:root {
  --color-primary: 0, 69, 128; /* Default primary color */
  --color-secondary: 255, 237, 74; /* Default secondary color */
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.button-overlay:hover::after {
  @apply pointer-events-none absolute inset-0 bg-white opacity-20;
  content: "";
}
